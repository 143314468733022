:root {
  --ff-body: 'Roboto', sans-serif;
  --ff-heading: 'Poppins', sans-serif;
}

body {
  font-family: var(--ff-body);
  margin: 0;
  padding: 0;
  background-color: #F0F2F8;
}

* {
  box-sizing: border-box;
}

html, body, #root, #root > div {
  height: 100%;
}

.grecaptcha-badge {
  display: none;
}
